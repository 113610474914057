import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import strapi from "../../../api/strapi"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import "./index.scss"
import { openModalSentMessage } from "../../../redux/actions/strapiActions"
import {
  gaCallClickEvent,
  gaContactInteraction,
} from "../../../helpers/functions/googleAnalytics"
import axios from "axios"

const ContactCards = ({
  zatrazi,
  openModalSentMessage,
  isOpenModalSentMessage,
}) => {
  const [isFieldActive, setActiveField] = useState(0)
  const inputRef = useRef(null)
  const isLegit = useRef(false)

  const { register, handleSubmit, watch, reset, errors } = useForm()

  let values = watch()

  useEffect(() => {
    if (!isOpenModalSentMessage) document.body.classList.remove("body-scroll")
  }, [isOpenModalSentMessage])

  useEffect(() => {
    reset("names")
    reset("email")
    reset("call_mobile")
    reset("contact_message")
    setActiveField(0)
  }, [zatrazi, reset, isOpenModalSentMessage])

  useEffect(() => {
    if (zatrazi) {
      gaCallClickEvent("Klik na zatrazi poziv sa kontakt stranice")
    }

    isLegit.current = false
  }, [zatrazi])

  useEffect(() => {
    return function cleanup() {
      if (inputRef.current) {
        gaContactInteraction("Korisnik odustao")
      }
    }
  }, [])

  const onSubmit = async data => {
    openModalSentMessage(!zatrazi? "inquiry" : null);

    isLegit.current = false

    if (zatrazi) {
      const responseZahtjevi = await strapi.post("/zahtjevis", {
        Ime_Prezime: data.names,
        Email: data.email,
        Broj_Mobitela: data.call_mobile,
      })

      if (responseZahtjevi.status === 200) {
        try {
          const resFuelMail = await axios.post(
            "https://mail.trebamosiguranje.hr/dalmatiko-mailer/v1/send",
            {
              type: "[Zatrazi poziv]",
              name: data.names,
              mobile: data.call_mobile,
              email: data.email,
              content: "",
            }
          )
          console.log("zatrazi poziv: ", resFuelMail)
        } catch (err) {
          console.error("Fuel Mailer Error: ", err.message)
        }
      }
    } else {
      const responseKontakt = await strapi.post("/kontakts", {
        Ime_Prezime: data.names,
        Email: data.email,
        Poruka: data.contact_message,
      })
      if (responseKontakt.status === 200) {
        try {
          const resFuelMail = await axios.post(
            "https://mail.trebamosiguranje.hr/dalmatiko-mailer/v1/send",
            {
              type: "[Kontakt forma]",
              name: data.names,
              mobile: "",
              email: data.email,
              content: data.contact_message,
            }
          )
          console.log("kontakt forma: ", resFuelMail)
        } catch (err) {
          console.error("Fuel Mailer Error: ", err.message)
        }
      }
    }

    if (isFieldActive) {
      gaContactInteraction("Korisnik unio sve podatke")
    }

    gaContactInteraction("Kontakt zatrazen")
  }

  const validateTelNumber = evt => {
    var theEvent = evt || window.event
    let key
    let keyCode

    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain")
    } else {
      key = theEvent.keyCode || theEvent.which

      keyCode = theEvent.keyCode || theEvent.which

      key = String.fromCharCode(key)
    }
    let regex = /([0-9]|[\-+/#])+$/i

    if (keyCode === 13) {
      evt.target.blur()
      handleSubmit(onSubmit)
    } else if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  }

  const gaInteraction = () => {
    console.log(isLegit.current)
    if (
      (values?.names?.length > 2 ||
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i.test(
          values?.call_mobile
        ) ||
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values?.email) ||
        values?.contact_message?.length > 0) &&
      !isLegit.current
    ) {
      gaContactInteraction("Prvi podatak unesen")
      // console.log("mrale")
      isLegit.current = true
    }

    if (
      values?.names?.length > 2 &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values?.email) &&
      (/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i.test(
        values?.call_mobile
      ) ||
        values?.contact_message?.length > 0) &&
      isLegit.current
    ) {
      gaContactInteraction("Korisnik unio sve podatke")
      // console.log("tu sam 2")
    }
  }

  const handleOnChange = e => {
    if (e.target.value.length === 0) {
      isLegit.current = false
    }

    inputRef.current = e.target.value
  }

  return (
    <>
      <div className={`contact-card-info ${zatrazi ? "remove" : ""}`}>
        <h3 className="contact-info-title">Kontakt informacije</h3>
        <p className="contact-info-desc1">info@trebamosiguranje.com</p>
        <p className="contact-info-desc1">091 456 6525 (Ines)</p>
        <p className="contact-info-desc1">099 253 1867 (Dea)</p>
        <p className="contact-info-desc1">099 532 1030 (Dino)</p>
        <p className="contact-info-desc1">Ruđera Boškovića 19, SPLIT</p>
        <div className="contact-info-desc2-holder">
          <p className="contact-info-desc2">
            DALMATIKO, obrt za zastupanje u osiguranju, MB:98235567, 2021.
          </p>
        </div>
      </div>
      <div className="contact-card-form">
        <div className="contact-form-title-holder">
          <h3 className="contact-form-title">
            {!zatrazi ? "Kontakt forma" : "Zatraži poziv"}
          </h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
          <div
            className={`name-email-holder ${
              isFieldActive === 1 || isFieldActive === 2 ? "active" : ""
            }`}
          >
            <label
              htmlFor="names"
              className={`name-label ${
                isFieldActive === 1 || values.names?.length > 0 ? "active" : ""
              } ${errors.names ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setActiveField(1)
              }}
            >
              Ime i prezime
            </label>
            <input
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                minLength: {
                  value: 2,
                  message: "Min name lenght is 2",
                },
              })}
              id="names"
              name="names"
              type="text"
              className={`contact-name ${isFieldActive === 1 ? "active" : ""} ${
                errors.names ? "error" : ""
              }`}
              onClick={e => {
                e.stopPropagation()
                setActiveField(1)
              }}
              onFocus={() => setActiveField(1)}
              onBlur={() => {
                setActiveField(0)
                gaInteraction()
              }}
              onChange={e => handleOnChange(e)}
            />
            <label
              htmlFor="email"
              className={`email-label ${
                isFieldActive === 2 || values.email?.length > 0 ? "active" : ""
              } ${errors.email ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setActiveField(2)
              }}
            >
              Email
            </label>
            <input
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              id="email"
              name="email"
              type="text"
              className={`contact-email ${
                isFieldActive === 2 ? "active" : ""
              } ${errors.email ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setActiveField(2)
              }}
              onFocus={() => setActiveField(2)}
              onBlur={() => {
                setActiveField(0)
                gaInteraction()
              }}
              onChange={e => handleOnChange(e)}
            />
          </div>
          <div className={`message-holder ${zatrazi ? "zatrazi" : ""}`}>
            <label
              htmlFor={!zatrazi ? "contact_message" : "call_mobile"}
              className={`message-label ${
                isFieldActive === 3 ||
                values.call_mobile?.length > 0 ||
                values.contact_message?.length > 0
                  ? "active"
                  : ""
              } ${zatrazi ? "zatrazi" : ""} ${
                errors.call_mobile || errors.contact_message ? "error" : ""
              }`}
              onClick={e => {
                e.stopPropagation()
                setActiveField(3)
              }}
            >
              {!zatrazi ? "Poruka" : "Broj mobitela"}
            </label>
            {zatrazi ? (
              <input
                type="tel"
                onKeyPress={e => validateTelNumber(e)}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i,
                    message: "Invalid mobile number",
                  },
                })}
                id="call_mobile"
                name="call_mobile"
                className={`contact-mobile ${
                  isFieldActive === 3 ? "active" : ""
                } ${errors.call_mobile ? "error" : ""}`}
                onClick={e => {
                  e.stopPropagation()
                  setActiveField(3)
                }}
                onFocus={() => setActiveField(3)}
                onBlur={() => {
                  setActiveField(0)
                  gaInteraction()
                }}
                onChange={e => handleOnChange(e)}
              />
            ) : (
              <textarea
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 1,
                    message: "Min message lenght is 1",
                  },
                })}
                id="contact_message"
                name="contact_message"
                className={`area-message ${
                  isFieldActive === 3 ? "active" : ""
                } ${errors.contact_message ? "error" : ""}`}
                onClick={e => {
                  e.stopPropagation()
                  setActiveField(3)
                }}
                onFocus={() => setActiveField(3)}
                onBlur={() => {
                  setActiveField(0)
                  gaInteraction()
                }}
                onChange={e => handleOnChange(e)}
              ></textarea>
            )}
          </div>
          <div
            className={`send-message-btn-holder ${zatrazi ? "zatrazi" : ""}`}
          >
            <button
              style={{ cursor: "pointer" }}
              type="submit"
              className={`send-message-btn ${zatrazi ? "zatrazi" : ""}`}
            >
              {!zatrazi ? "Pošalji poruku" : "Zatraži poziv"}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpenModalSentMessage: state.strapiReducer.isOpenModalSentMessage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalSentMessage: bindActionCreators((type) => openModalSentMessage(type), dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactCards)
