import React, { useEffect, useState } from "react"
import Header from "../browser/components/Header"
import ContactPageContent from "../views/ContactPageContent"
import Footer from "../universal/components/Footer"
import Loader from "../universal/components/Loader"
import Helmet from "react-helmet"
import { showCookieBanner } from "../helpers/functions"
import Cookies from "../universal/components/Cookies"

const ContactPage = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    })

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])
  useEffect(() => {
    document.title = "Dalmatiko osiguranje | Kontakt"
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dalmatiko | Kontakt</title>
        <link rel="canonical" href="https://dalmatiko-osiguranje.hr/contact" />
        <meta
          name="description"
          content="Kontaktiraj Dalmatiko osiguranje. Provjeri najnovije osiguravajuće ponude. Pronađi paket osiguranja. Kontakt stranica."
        />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <ContactPageContent />
          {showCookieBanner() ? <Cookies /> : null}
          <Footer />
        </>
      )}
    </>
  )
}

export default ContactPage
