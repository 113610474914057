import React, { useState } from "react"
import ContactCards from "../ContactCards"
import "./index.scss"

const ContactBtns = () => {
  const [isZatraziPoziv, setZatraziPoziv] = useState(0)

  return (
    <>
      <div
        className={"contact-btns-holder" + (isZatraziPoziv ? " active" : "")}
      >
        <div className="contact-btns-list">
          <button
            className={"contact-item-btn" + (!isZatraziPoziv ? " active" : "")}
            onClick={() => setZatraziPoziv(0)}
            style={{ cursor: "pointer" }}
          >
            Kontakt
          </button>
          <button
            className={"contact-item-btn" + (isZatraziPoziv ? " active" : "")}
            onClick={() => setZatraziPoziv(1)}
            style={{ cursor: "pointer" }}
          >
            Zatraži poziv
          </button>
        </div>
      </div>

      <div
        className={`contact-cards-holder ${isZatraziPoziv ? "zatrazi" : ""}`}
      >
        <ContactCards zatrazi={isZatraziPoziv} />
      </div>
    </>
  )
}

export default ContactBtns
