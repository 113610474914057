import React from "react"
import ContactBtns from "../../universal/components/ContactBtns"
import ContactTitle from "../../universal/components/ContactTitle"
import "./index.scss"
import "../root_anims.scss"

const ContactPageContent = () => {
  return (
    <div className="contact-page-content-holder">
      <ContactTitle />
      <ContactBtns />
    </div>
  )
}

export default ContactPageContent
