import React from "react"
import "./index.scss"

const ContactTitle = () => {
  return (
    <div className="contact-title-holder">
      <h2 className="contact-title">
        Trebaš{" "}
        <span className="contact-title-part">
          pomoć?<span className="contact-title-line"></span>
        </span>{" "}
        Tu smo.
      </h2>
    </div>
  )
}

export default ContactTitle
